import React, { useState } from 'react';
import logo from '../Images/logo-main-black.png';
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCommentSms, faGlobe, faFileExport, faReceipt, faDollar, faVideoCamera, faUserInjured, faEnvelope, faBicycle, faUsers, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from "firebase/auth";

function LeftBar() {
    let navigate = useNavigate();
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            navigate("/login");
        }
    });

    const [isCollapsed1, setIsCollapsed1] = useState(true);
    const [isCollapsed2, setIsCollapsed2] = useState(true);
    const [isCollapsed3, setIsCollapsed3] = useState(true);
    const [isCollapsed4, setIsCollapsed4] = useState(true);

    const toggleCollapse1 = () => setIsCollapsed1(!isCollapsed1);
    const toggleCollapse2 = () => setIsCollapsed2(!isCollapsed2);
    const toggleCollapse3 = () => setIsCollapsed3(!isCollapsed3);
    const toggleCollapse4 = () => setIsCollapsed4(!isCollapsed4);

    return (
        <div className='leftNav'>
            <div className='logoContainer'>
                <img src={logo} alt="" className='dashnavLogo' />
            </div>
            <br />
            <p>HOME</p>
            <NavLink to="/dashboard" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faHome} className="laftNavIcon activelaftNavIcon" />
                Dashboard
            </NavLink>
            <p>STUDENTS</p>
            <NavLink to="/applications" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faUsers} className="laftNavIcon" />
                My Applicants
            </NavLink>

            <NavLink to="/marketing-materials" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faFileExport} className="laftNavIcon" />
                Marketing materials
            </NavLink>

            <NavLink to="/earnings" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faReceipt} className="laftNavIcon" />
                My earnings
            </NavLink>

            <NavLink to="/payments" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faDollar} className="laftNavIcon " />
                My Payments 
            </NavLink>

            <p>ACCOUNT</p>
            <NavLink to="/profile" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
                <FontAwesomeIcon icon={faUser} className="laftNavIcon " />
                Profile
            </NavLink>
        </div>
    );
}

export default LeftBar;
