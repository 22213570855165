import React, { useState, useEffect } from 'react';
import './dash.css';
import dashOne from '../Images/dashone.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { db } from '../firebase';
import { db2 } from '../firebaseTwo.js';
import { collection, query, getDocs, where, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function HomeComp() {
  // States
  const [loggedInUser, setLoggedInUser] = useState('');
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [activeStudents, setActiveStudents] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthlyData, setMonthlyData] = useState({
    Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
    Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
  });
  const [referralCode, setReferralCode] = useState("");

  // Generate list of years from 2020 to the current year
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear; year++) {
    years.push(year);
  }

  // Function to handle button clicks
  const handleClick = (year) => {
    setSelectedYear(year);
  };

  // Fetch referral code
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const q = query(collection(db, "users"), where("userId", "==", uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const username = doc.data().fullname; 
          setLoggedInUser(username);
          setReferralCode(doc.data().referralCode);
        });
      }
    });
  }, []);

  // Fetch data for the selected year
  useEffect(() => {
    const fetchData = async () => {
      if (referralCode) {
        const q = query(
          collection(db2, 'users'),
          where('referralCode', '==', referralCode),
          where('appDate', '>=', Timestamp.fromDate(new Date(`${selectedYear}-01-01`))),
          where('appDate', '<=', Timestamp.fromDate(new Date(`${selectedYear}-12-31`)))
        );
        const querySnapshot = await getDocs(q);
        const data = {
          Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
          Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
        };

        querySnapshot.forEach((doc) => {
          const appDate = doc.data().appDate.toDate();
          const month = appDate.getMonth(); // 0 for January, 11 for December
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          data[monthNames[month]] += 1;
        });

        setMonthlyData(data);
      }
    };

    fetchData();
  }, [selectedYear, referralCode]);

  // Fetch total applicants
  useEffect(() => {
    const fetchData = async () => {
      if (referralCode) {
        const q = query(collection(db2, 'users'), where('userStatus', '==', 'inactive'), where("referralCode", "==", referralCode));
        const querySnapshot = await getDocs(q);
        const studentsItem = [];
        querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
        });
        setTotalApplicants(studentsItem.length);
      }
    };
    fetchData();
  }, [referralCode]);

  // Fetch active students
  useEffect(() => {
    const fetchData = async () => {
      if (referralCode) {
        const q = query(collection(db2, 'users'), where('userStatus', '==', 'Student'), where("referralCode", "==", referralCode));
        const querySnapshot = await getDocs(q);
        const studentsItem = [];
        querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
        });
        setActiveStudents(studentsItem.length);
      }
    };
    fetchData();
  }, [referralCode]);

  const [earningsThisMonth, setEarningsThisMonth] = useState(0);
  const [paymentsThisMonth, setPaymentsThisMonth] = useState(0);

    // Fetch monthly earnings and payments
    // useEffect(() => {
    //   const fetchMonthlyEarningsAndPayments = async () => {
    //     if (referralCode) {
    //       const currentMonth = new Date().getMonth(); // 0 for January, 11 for December
    //       const monthStart = new Date(new Date().getFullYear(), currentMonth, 1);
    //       const monthEnd = new Date(new Date().getFullYear(), currentMonth + 1, 0);
  
    //       // Fetch earnings
    //       const earningsQuery = query(
    //         collection(db, 'users'),
    //         where('referralCode', '==', referralCode),
    //         where('date', '>=', Timestamp.fromDate(monthStart)),
    //         where('date', '<=', Timestamp.fromDate(monthEnd))
    //       );
    //       const earningsSnapshot = await getDocs(earningsQuery);
    //       let earnings = 0;
    //       earningsSnapshot.forEach((doc) => {
    //         earnings += parseFloat(doc.data().amount);
    //       });
    //       setEarningsThisMonth(earnings);
  
    //       // Fetch payments
    //       const paymentsQuery = query(
    //         collection(db2, 'payments'),
    //         where('referralCode', '==', referralCode),
    //         where('date', '>=', Timestamp.fromDate(monthStart)),
    //         where('date', '<=', Timestamp.fromDate(monthEnd))
    //       );
    //       const paymentsSnapshot = await getDocs(paymentsQuery);
    //       let payments = 0;
    //       paymentsSnapshot.forEach((doc) => {
    //         payments += parseFloat(doc.data().amount);
    //       });
    //       setPaymentsThisMonth(payments);
    //     }
    //   };
    //   fetchMonthlyEarningsAndPayments();
    // }, [referralCode]);

  return (
    <div className='dashComp'>

      <div className='dashFlex1'>
        
        <div className='dash1'>
          <div className='dash1Cont'>
            <h4>Welcome</h4>
            <p>{loggedInUser}</p>
          </div>
          <img src={dashOne} alt="" />
        </div>

        <div className='dash2'>
          <div className='dChild1 bg-warning'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faCalendarAlt} className="" />
              <h5>{activeStudents}</h5>
            </div>
            <p>Admitted Applicants</p> 
          </div>

          <div className='dChild2 bg-primary'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faCalendarAlt} className="" />
              <h5>KES. {parseFloat(totalIncome).toLocaleString()}</h5>
            </div>
            <p>My Earnings This Month</p>
          </div>

          <div className='dChild3 bg-success'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faCalendarAlt} className="" />
              <h5>{totalApplicants}</h5>
            </div>
            <p>Total Applications</p>
          </div>
 
          <div className='dChild4 bg-danger'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faCalendarAlt} className="" />
              <h5> KES. {parseFloat(totalExpenses).toLocaleString()}</h5>
            </div>
            <p>My Payments This Month</p>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className='feelineGraph'>
        <Line
          className='theFeeLineGraph'
          datasetIdKey='id'
          data={{
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
              {
                id: 1,
                label: `Applicants over time with referral code - ${referralCode} (${selectedYear})`,
                data: [
                  monthlyData.Jan, monthlyData.Feb, monthlyData.Mar, monthlyData.Apr,
                  monthlyData.May, monthlyData.Jun, monthlyData.Jul, monthlyData.Aug,
                  monthlyData.Sep, monthlyData.Oct, monthlyData.Nov, monthlyData.Dec
                ],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
              }
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y.toLocaleString('en-US');
                    }
                    return label;
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default HomeComp;
 