import React, { useRef, useState } from 'react';
import logo from '../Images/logo-main-black.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';


// Firebase
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

function Login() {
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [showProgress, setShowProgress] = useState(false);
  const [showSignInbtn, setShowSignInbtn] = useState(true);

  function SignInUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const auth = getAuth();


    setShowProgress(true);
    setShowSignInbtn(false);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        let userID = userCredential.user.uid;

        // Show success message
        toast.success('Welcome back! Redirecting to the dashboard...');
        navigate("/dashboard"); // Redirect to homepage 
      })
      .catch((error) => {
        const errorMessage = error.message;
        setShowProgress(false);
        setShowSignInbtn(true);

        // Show error message
        //swal('Login Error!', errorMessage, 'error');
        toast.error(errorMessage, 'Login Error');
      });
  }

  return (
    <div className='d-flex loginContainer'>
<div className='loginLeft'>
  <video src="https://res.cloudinary.com/ubunifu/video/upload/v1723917248/6987044-uhd_2160_3840_25fps_c96mfk.mp4" muted autoplay loop playsinline></video>
</div>
 

      <div className='loginRight'>
        <Link to="/"><img src={logo} alt="" className='loginLogo' /></Link>
        <h5>Welcome back!</h5>
        <h6>Enter your email address and password to access your Account.</h6>

        <Form.Label className="inputLabel">Email</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faEnvelope} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Enter your email"
            aria-label="Enter your email"
            type="email"
            aria-describedby="basic-addon1"
            className='loginForm'
            ref={emailRef}
          />
        </InputGroup>

        <Form.Label className="inputLabel">Password</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faLock} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Enter your Password"
            aria-label="Enter your Password"
            type="password"
            aria-describedby="basic-addon1"
            className='loginForm'
            ref={passwordRef}
          />
        </InputGroup>

        {showSignInbtn &&
          <button type="submit" onClick={SignInUser} className='signInbtnn'>
            Sign In
          </button>
        }

        {showProgress &&
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span> Logging in...</span>
          </Button>
        }

        <Link to="/signup" className='signupLink '>Sign up for an account</Link>

        <ToastContainer />
      </div>
    </div>
  );
}

export default Login;
 