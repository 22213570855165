import React, { useState, useRef } from 'react'
import coverPhotoContImage from '../Images/default-cover.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//routers
import {useNavigate } from "react-router-dom";

function EditProfComp() {
  //states
  const [loggedInUser, setLoggedInUser] = useState("");
  const [userNationality, setNationality] = useState("");

    //states
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userWebsite, setUserWebsite] = useState("");
    const [userAbout, setUserAbout] = useState("");
    const [userExpectation, setUserExpectation] = useState("");
    const [userCourse, setUserCourse] = useState("");
    const [userSessionTime, setUserSessionTime] = useState("");
    const [userClassType, setUserClassType] = useState("");
  
  
    //update user state
    const [updateUserWebsite, setUpdateUserWebsite] = useState("");
    const [updateUserGithub, setUserGithub] = useState("");
    const [updateHighSchoolMath, setHighSchoolMath] = useState("");
    const [updateEducationLevel,setEducationLevel] = useState("");

      //get data from input
  const educationRef = useRef();
  const highschoolRef = useRef();
  const phoneRef = useRef();
  const githubRef = useRef();
  const websiteRef = useRef();
  const aboutMeRef = useRef();
  const expectationsRef = useRef();


  const [userProfilePicture, setProfilePicture] = useState("");
  const [userCoverPicture, setCoverPicture] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertTwo, setShowAlertTwo] = useState(false);
  const [UploadBtn, setUploadBtn] = useState("Upload");
  const [uploadProfileStatus, setuploadProfileStatus] = useState("1");
  const [uploadStatStatus, setuploadStatStatus] = useState("Uploading...0%");



  //use ref
  const fileInput = useRef();
  const coverInput = useRef();

  //instantiate storage
  const storage = getStorage();

  //instantiate aut
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
    //if user is logged in 
    const uid = user.uid;

    const q = query(collection(db, "users"), where("userId", "==", uid));
    getDocs(q).then((QuerySnapshot)=>{
        QuerySnapshot.forEach((doc)=>{
        const username = doc.data().fullname;
        const nationality = doc.data().country;
        const profilePic = doc.data().profilePic;
        const coverPhoto = doc.data().coverPhoto;

        const whyInterested = doc.data().whyInterested;
        const aboutMe = doc.data().aboutMe;
        const phoneNumber = doc.data().phone;
        const userEmail = doc.data().userEmail;
        const userWebsite = doc.data().userWebsite;
        const userGithub = doc.data().userGithub;
        const highSchoolMath =  doc.data().highSchoolMath;
        const educationLevel = doc.data().educationLevel;

        const course = doc.data().course;
        const sessionTime = doc.data().sessionTime;
        const classType = doc.data().classType;


        setUserEmail(userEmail);
        setUserPhone(phoneNumber);
        setUserWebsite(userWebsite);
        setUserAbout(aboutMe);
        setUserExpectation(whyInterested);

        setUserCourse(course);
        setUserSessionTime(sessionTime);
        setUserClassType(classType);

        //update
        setUpdateUserWebsite(userWebsite);
        setUserGithub(userGithub);
        setHighSchoolMath(highSchoolMath);
        setEducationLevel(educationLevel);

        setLoggedInUser(username);
        setNationality(nationality);
        //setProfilePicture(profilePic);
        //setCoverPicture(coverPhoto);

        let myxx = profilePic + "1";
        let myYY = coverPhoto + "1";


        if(myxx === "undefined1"){
          setProfilePicture("");         
        }else{
          setProfilePicture(profilePic);
        }

        //
        if(myYY === "undefined1"){
          setCoverPicture(coverPhotoContImage);
        }else{
          setCoverPicture(coverPhoto);
        }

        })
    })

    //update user profile
    window.updateProfile = function(){

    const phoneValue = phoneRef.current.value;
    const aboutMeValue = aboutMeRef.current.value;


    updateDoc(doc(db, "users", user.uid), {
        phone: phoneValue,
        aboutMe:aboutMeValue,


    }).then(()=>{
        window.location.reload(false);
    })     
    
    }
    //end

    //update profile picture 
    window.saveProfilePhoto = function(){
        const profilePhoto = fileInput.current.files[0];   

        const storageRef = ref(storage, 'images/' + Math.random() + profilePhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                setShowAlert(true);              
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedOffProgress = Math.round(progress);

                setuploadProfileStatus(roundedOffProgress);
                setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                setUploadBtn("Uploading.."+roundedOffProgress +'%')
                
            }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                updateDoc(doc(db, "users", user.uid), {
                    profilePic: downloadURL

                }).then(()=>{
                    swal("Success", "Profile picture updated successfully!", "success").then((value) => {
                        window.location.reload(false);
                      });
                    //
                })  
            });
        }
        );
    }
    //end profile picture


    //update Cover photo
    window.saveCoverPhoto = function(){

        const coverPhoto = coverInput.current.files[0];   

        const storageRef = ref(storage, 'images/' + Math.random() + coverPhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, coverPhoto);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                setShowAlertTwo(true);              
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedOffProgress = Math.round(progress);

                setuploadProfileStatus(roundedOffProgress);
                setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                setUploadBtn("Uploading.."+roundedOffProgress +'%')
                
            }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                updateDoc(doc(db, "users", user.uid), {
                    coverPhoto: downloadURL

                }).then(()=>{
                    swal("Success", "Cover photo updated successfully!", "success").then((value) => {
                        window.location.reload(false);
                      });
                    //
                })  
            });
        }
        );

    }
        } else {
        // User is signed out
        }
    });

    //update profile picture on select
    function updateProfilePhoto(){
        const profilePhoto = fileInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setProfilePicture(url)
    }

    //update profile picture on select
    function updateCoverPhoto(){
        const profilePhoto = coverInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setCoverPicture(url)
    }

    //go to profile
    let navigate = useNavigate();
    function goToProfilePage(){
        navigate("/profile")
    }


  return (
    <div >
        <div className='profileComp'>
            <div className='profileTop'>
                <div className='coverPhotoCont'>
                    <img src={userCoverPicture} alt="" />
                </div>

                <div className='profilePictureCont'>
                    <img src={userProfilePicture} alt="" />
                </div>
                <button className='editAccountMobile' onClick={goToProfilePage}><FontAwesomeIcon icon={faArrowLeft} className="profileLocationIcon"/> Back</button>

                <div className='profileInfoCont'>
                <div>
                    <h3>{loggedInUser}</h3>
                    <p> <FontAwesomeIcon icon={faMapMarkerAlt} className="profileLocationIcon"/> {userNationality}</p>
                </div>      
                
            </div>
            </div> 

            <div className='EditProfileBody'>
                <h6>Update Profile & Cover Photos</h6> 
                <br />

                <p>Profile Picture</p>
                <div className='d-flex'>         
                    <input type="file" ref={fileInput} className="form-control" onChange={updateProfilePhoto}/>             
                    <button onClick={window.saveProfilePhoto}>{UploadBtn}</button>
                </div>
                <br />

                {showAlert && 
                    <Alert>
                        {uploadStatStatus}
                        <ProgressBar striped variant="info" now={uploadProfileStatus} />
                    </Alert>
                }

                <p>Cover Photo</p>
                <div className='d-flex'>         
                    <input type="file" ref={coverInput} className="form-control" onChange={updateCoverPhoto}/>             
                    <button onClick={window.saveCoverPhoto}>{UploadBtn}</button>
                </div>
                <br />

                {showAlertTwo && 
                    <Alert>
                        {uploadStatStatus}
                        <ProgressBar striped variant="info" now={uploadProfileStatus} />
                    </Alert>
                }



            </div>


            <div className='EditProfileBody2'>
                <h6>Account Details</h6>
                
                <div>

                    <label htmlFor="">Phone number</label>
                    <input type="text" className='form-control mb-3'ref={phoneRef} defaultValue={userPhone} placeholder='eg +254'/>

                    <label htmlFor="">About Me</label>
                    <textarea className='form-control mb-3' rows="3" ref={aboutMeRef} defaultValue={userAbout} ></textarea>



                  </div>


                  <Button onClick={window.updateProfile}>Update Info</Button>
    

            </div>

        </div>
       
    </div>
  )
} 

export default EditProfComp