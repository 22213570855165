// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7J8Rjo0upx92d43j9m_twn9xAwm_x3nQ",
  authDomain: "ref-program.firebaseapp.com",
  projectId: "ref-program",
  storageBucket: "ref-program.appspot.com",
  messagingSenderId: "468294343849",
  appId: "1:468294343849:web:a9fb85261063cc950e1db8",
  measurementId: "G-PT7Z3PTVFC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(); 

export {app};
export {analytics}
export {db}