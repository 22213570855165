import React, { useState, useEffect, useRef } from 'react';
import { getFirestore } from "@firebase/firestore";
import { app2 } from '../firebaseTwo.js';
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc, query, getDocs, where } from "@firebase/firestore"; 
import Table from 'react-bootstrap/Table';
import { Spinner } from 'react-bootstrap'; // Import Spinner component
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'; 
import { faAdd } from '@fortawesome/free-solid-svg-icons';  
import Modal from 'react-bootstrap/Modal';  
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';  
import swal from 'sweetalert'; 
 
function PaymentsComp() {
  const db = getFirestore(app2);
  const db1 = getFirestore(app);
  const auth = getAuth();
  let navigate = useNavigate();

  const [studentsList, setStudentList] = useState([]);
  const [countAllData, setcountAllData] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [totalEarnings, setTotalEarnings] = useState(0); // Add state for total earnings
  const [modalShowUp, setModalShowUp] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0); // State for total paid amount
  const [totalBalance, setTotalBalance] = useState(0); // State for available balance
  const [theWithdrawalLimit, setWithdrawalLimit] = useState(0);
  const [theMaxWithdraw, setMaxWithdraw] = useState(0);
 
  const courseEarnings = {
    "Full-Stack Software Development": 3000,
    "Full-Stack JavaScript Dev": 2500,
    "Web Development": 2000,
    "Mobile App Development": 2500,
    "Fundamentals of Conversational AI": 1000,
    "Robotics": 5000,
    "Data Science": 3000,
    "Blockchain": 3000,
    "UI/UX Design": 1000,
    "Machine Learning for Mobile (Android/iOS)": 3000,
    "Machine Learning for JavaScript (Web)": 3000,
    "Machine Learning & Deep Learning": 3000,
    "iOS Development (Mobile App)": 3000
  };

  useEffect(() => {
    const fetchUserReferralCode = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) { 
          const uid = user.uid;
          const q = query(collection(db1, "users"), where("userId", "==", uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setReferralCode(doc.data().referralCode);
          });
        }
      });
    };

    fetchUserReferralCode();
  }, [auth, db1]);

  useEffect(() => {
    const fetchData = async () => {
      if (referralCode) {
        setLoading(true); // Start loading
        const q = query(
          collection(db, 'users'),
          orderBy("appDate", 'desc'),
          where("userStatus", "==", "Student"),
          where("referralCode", "==", referralCode)
        );
        const querySnapshot = await getDocs(q);
        const studentsItem = [];
        let total = 0; // Variable to accumulate total earnings

        querySnapshot.forEach((doc) => {
          const studentData = { id: doc.id, ...doc.data() };
          studentsItem.push(studentData);

          // Calculate earnings based on course
          const earnings = courseEarnings[studentData.course] || 0;
          total += earnings;
        });

        setcountAllData(studentsItem.length);
        setTotalEarnings(total); // Update total earnings state
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [referralCode, db]);

  useEffect(() => {
    const fetchPayments = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const q = query(
            collection(db1, 'Payments'),
            orderBy("myTimestamp", 'desc'),
            where("userID", "==", uid)
          );
          const querySnapshot = await getDocs(q);
          const paymentsList = [];
          let totalPaidAmount = 0;
          let withdrawalLimit = 0;
  
          querySnapshot.forEach((doc) => {
            const paymentData = { id: doc.id, ...doc.data() };
            paymentsList.push(paymentData);
  
            // Calculate total amount for payments with status "paid"
            if (paymentData.status === "paid") {
              totalPaidAmount += parseFloat(paymentData.amount);
            }
  
            // Include both "paid" and "pending" payments in the total calculation
            if (paymentData.status === "paid" || paymentData.status === "Pending") {
              withdrawalLimit += parseFloat(paymentData.amount);
            }
          });
  
          // Calculate available balance and maximum withdrawable amount
          const availableBalance = totalEarnings - totalPaidAmount;
          const maxWithdrawable = availableBalance - withdrawalLimit;
  
          // Update state in the correct sequence
          setStudentList(paymentsList);
          setTotalPaid(totalPaidAmount); // Update total paid amount
          setTotalBalance(availableBalance); // Update available balance
          setWithdrawalLimit(withdrawalLimit); // Set withdrawal limit
          setMaxWithdraw(maxWithdrawable); // Set maximum withdrawable amount
        }
      });
    };
  
    fetchPayments();
  }, [auth, db1, totalEarnings]);
  

  const withdrawalAmount = useRef();
  const mpesaNumber = useRef();

  function requestPayment() {
    const phoneNumber = mpesaNumber.current.value;
    const amount = parseFloat(withdrawalAmount.current.value); // Convert amount to float
    const timestamp = new Date(); 
  
    if (amount > theMaxWithdraw) {
      swal("Error", "Withdrawal amount exceeds available balance.", "error");
      return;
    }else{

      document.getElementById("submitBtn").style.display = "none";
      document.getElementById("spinnerbtn").style.display = "block";
    
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const newPayment = doc(collection(db1, "Payments"));
          setDoc(newPayment, {
            phoneNumber: phoneNumber,
            amount: amount,
            docid: newPayment.id,
            myTimestamp: timestamp,
            userID: uid,
            status: "Pending" 
          }).then(()=>{
            swal("Request Made", "Your Withdrawal request has been made.", "success").then(() => {
              window.location.reload();
            });
          })

        } else {
          console.log("User not authenticated");
          swal("Error", "You need to be logged in to make a request.", "error");
          document.getElementById("submitBtn").style.display = "block";
          document.getElementById("spinnerbtn").style.display = "none";
        }
      });
    }

  }
  
  function MyUpdateModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Make a Withdrawal Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='alert alert-primary weirdAlert'>
                  Maximum amount I can withdraw: KES. <strong> {theMaxWithdraw.toLocaleString()} </strong>. This is less pending and withdrawn amounts 
              </div> 

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Amount</Form.Label>
                <Form.Control 
                  type="number" 
                  placeholder="Enter amount"
                  ref={withdrawalAmount} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Mpesa Phone number</Form.Label>
                <Form.Control 
                  type="number" 
                  placeholder="Enter phone number"
                  ref={mpesaNumber} />
              </Form.Group>
              <div className='alert alert-primary weirdAlert'> 
                After request is made, disbursment is done within 24 hours from Choice Bank
              </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>
                <Button variant="primary" id='submitBtn' onClick={requestPayment}>Request</Button>
                <Button variant="primary" disabled id='spinnerbtn' style={{ display: 'none' }}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span> Requesting...</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
 

  return (
    <div className="clientsComp">
      <div className="dataCompTop paymentTopCont">
        <h5>All Payments </h5>
        <button className='viewBtn3 btn btn-primary' onClick={() => setModalShowUp(true)}><FontAwesomeIcon icon={faAdd} /> Withdraw Cash</button>
      </div>
      <br/>

      <div className='paymentsDivContainer'>
        
        <div className='dChild22 bg-success'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faCalendarAlt} className="" />
            <h5> KES. {totalBalance.toLocaleString()}</h5> {/* Display available balance */}
          </div>
          <p>Available Balance</p>
        </div>

        <div className='dChild22 bg-primary'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faCalendarAlt} className="" />
            <h5> KES. {totalEarnings.toLocaleString()}</h5> {/* Display total earnings */}
          </div>
          <p>Total Earnings over time</p>
        </div>

        <div className='dChild22 bg-warning'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faCalendarAlt} className="" />
            <h5> KES. {totalPaid.toLocaleString()}</h5> {/* Display total withdrawal */}
          </div>
          <p>Total withdrawal over time</p>
        </div>
      </div>
      <br/>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Request Date</th>
              <th>M-Pesa Phone Number</th>
              <th>Amount</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
          {studentsList.map((mystudent) => (
                <tr key={mystudent.id}> 
                  <td>{mystudent.myTimestamp.toDate().toDateString()} at {mystudent.myTimestamp.toDate().toLocaleTimeString()} </td>
                  <td>{mystudent.phoneNumber}</td>
                  <td>{mystudent.amount}</td>
                  <td>{mystudent.status}</td>
                </tr>
              ))}
          </tbody> 
        </Table>
      )}

    <MyUpdateModal show={modalShowUp} onHide={() => setModalShowUp(false)} />
    </div>
  );
} 

export default PaymentsComp;
 