import React from 'react'
import LeftBar from '../Components/LeftBar'
import Dashnav from '../Components/Dashnav'
import HomeComp from './HomeComp'

function Home() {
  return (
    <div className='mainContainer'>
        <LeftBar/>
        <div className='theMainContainer'>
            <Dashnav/>
            <HomeComp/>      
        </div>
    </div>
  )
}

export default Home