import React, { useState, useEffect } from 'react';
import { getFirestore } from "@firebase/firestore";
import { app2 } from '../firebaseTwo.js';
import { app } from '../firebase.js';
import { collection, query, getDocs, orderBy, where } from "@firebase/firestore"; 
import Table from 'react-bootstrap/Table';
import { Spinner } from 'react-bootstrap'; // Import Spinner component
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

function AppComp() { 
  const db = getFirestore(app2);
  const db1 = getFirestore(app);
  const auth = getAuth();
  let navigate = useNavigate();

  const [studentsList, setStudentList] = useState([]);
  const [countAllData, setcountAllData] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  const courseEarnings = {
    "Full-Stack Software Development": 3000,
    "Full-Stack JavaScript Dev": 2500,
    "Web Development": 2000,
    "Mobile App Development": 2500,
    "Fundamentals of Conversational AI": 1000,
    "Robotics": 5000,
    "Data Science": 3000,
    "Blockchain": 3000,
    "UI/UX Design": 1000,
    "Machine Learning for Mobile (Android/iOS)": 3000,
    "Machine Learning for JavaScript (Web)": 3000,
    "Machine Learning & Deep Learning": 3000,
    "iOS Development (Mobile App)": 3000
  };

  useEffect(() => {
    const fetchUserReferralCode = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) { 
          const uid = user.uid;
          const q = query(collection(db1, "users"), where("userId", "==", uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setReferralCode(doc.data().referralCode);
          });
        }
      });
    };

    fetchUserReferralCode();
  }, [auth, db1]);

  useEffect(() => {
    const fetchData = async () => {
      if (referralCode) {
        setLoading(true); // Start loading
        const q = query(
          collection(db, 'users'),
          orderBy("appDate", 'desc'),
          where("referralCode", "==", referralCode)
        );
        const querySnapshot = await getDocs(q);
        const studentsItem = [];
        querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
        });
        setStudentList(studentsItem);
        setcountAllData(studentsItem.length);
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [referralCode, db]);

  function viewStudent(userId) {
    navigate('/view-student', { state: { myData: userId } });
  }

  return (
    <div className="clientsComp">
      <div className="dataCompTop">
        <h5>My Recent Applications <span className="badge text-bg-success">{countAllData}</span></h5>
      </div>
      <br/>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Application Date</th>
              <th>Student's First Name</th>
              <th>Course</th>
              <th>Cohort Date</th>
              <th>Potential Earnings</th>
              <th>Applicant's Status</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => {
              // Extract the first name
              const firstName = mystudent.bio.split(' ')[0]; 

              // Determine the status message based on userStatus
              const statusMessage = mystudent.userStatus === "inactive" ? "Not Yet Admitted" : 
                                    mystudent.userStatus === "Student" ? "Admitted" : 
                                    mystudent.userStatus;

              return (
                <tr key={mystudent.id}>
                  <td>{mystudent.appDate.toDate().toDateString()} at {mystudent.appDate.toDate().toLocaleTimeString()}</td>
                  <td>{firstName}</td>
                  <td>{mystudent.course}</td>
                  <td>{mystudent.cohort}</td> 
                  <td>KES. {courseEarnings[mystudent.course]?.toLocaleString() || "0"}</td>
                  <td>{statusMessage}</td>
                </tr>
              );
            })}
          </tbody> 

        </Table>
      )}
    </div>
  );
}

export default AppComp;
  