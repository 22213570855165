import React from 'react'
import LeftBar from '../Components/LeftBar'
import Dashnav from '../Components/Dashnav'
import PaymentsComp from './PaymentsComp'

function Payments() {
  return (
    <div className='mainContainer'>
        <LeftBar/>
        <div className='theMainContainer'>
            <Dashnav/>
            <PaymentsComp/>      
        </div>
    </div>
  )
}

export default Payments