import React, { useState, useEffect } from 'react';
import { getFirestore } from "@firebase/firestore";
import { app2 } from '../firebaseTwo.js'; 
import { collection, query, getDocs } from "@firebase/firestore"; 

function MarkettingComp() {
  const db = getFirestore(app2);
  const [billsList, setBillsList] = useState([]);

  useEffect(() => { 
      const fetchBills = async () => {
          const q = query(collection(db, 'MarketingMaterial'));
          const querySnapshot = await getDocs(q);
          const bills = [];
          querySnapshot.forEach((doc) => {
              bills.push({ id: doc.id, ...doc.data() });
          });
          setBillsList(bills);
      };
      fetchBills();
  }, []); 

  const renderMedia = (mediaUrl) => {
    if (!mediaUrl) {
      return <p>No media available</p>;
    }

    if (mediaUrl.includes('.jpg') || mediaUrl.includes('.jpeg') || mediaUrl.includes('.png') || mediaUrl.includes('.gif')) {
      return <img src={mediaUrl} alt="Marketing Material" style={{ maxWidth: '100%' }} />;
    } else if (mediaUrl.includes('.mp4') || mediaUrl.includes('.webm') || mediaUrl.includes('.mov') || mediaUrl.includes('.ogg')) {
      return <video src={mediaUrl} controls style={{ maxWidth: '100%' }} />; 
    } else {
      return <p>Unsupported media type</p>;
    }
  };

  const handleView = (mediaUrl) => {
    window.open(mediaUrl, '_blank'); // Open media URL in a new tab
  };

  const handleDownload = (mediaUrl) => {
    const link = document.createElement('a');
    link.href = mediaUrl;
    link.download = mediaUrl.substring(mediaUrl.lastIndexOf('/') + 1);
    link.target = '_self'; // Ensure the link opens in the same tab for direct download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="clientsComp">
      <div className="dataCompTop">
        <h5>Marketing Materials</h5>
      </div>
      <br/>

      {billsList.map((bill) => ( 
        <div className='materialComp' key={bill.id}> 
          <div className='materialMediaContainer'>
            {renderMedia(bill.mediaUrl)}
          </div>
          <div className='materialCompBottom'>
            <div className='materialDownloadContainer'>
              <h6>Caption</h6>
              <div>
                <button onClick={() => handleView(bill.mediaUrl)}>View</button>
                <button onClick={() => handleDownload(bill.mediaUrl)}>Download</button>
              </div>
            </div>
            <p>{bill.caption}</p> 
          </div>
        </div>
      ))}
    </div>
  )
}

export default MarkettingComp;
 