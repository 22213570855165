import React, { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { collection, query, getDocs, where, doc, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { db } from '../firebase';
import logo from '../Images/logo-main-black.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faPhone, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import CountryOptions from '../Components/CountryOptions';
import { serverTimestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Register() {
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const fullNameRef = useRef();
  const phoneNumberRef = useRef();
  const countryRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSignUpBtn, setShowSignUpBtn] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  async function generateReferralCode() {
    const generateCode = () => Math.floor(100000 + Math.random() * 900000).toString();
    let referralCode = generateCode();
  
    // Check if the code already exists in the database
    const referralCollection = collection(db, 'users');
    const q = query(referralCollection, where('referralCode', '==', referralCode));
    const querySnapshot = await getDocs(q);
  
    // If the code exists, generate a new one
    while (!querySnapshot.empty) {
      referralCode = generateCode();
    }
  
    return referralCode;
  }
  
  async function SignUpUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const fullName = fullNameRef.current.value;
    const phoneNumber = phoneNumberRef.current.value;
    const country = countryRef.current.value;
    const auth = getAuth();
  
    setShowProgress(true);
    setShowSignUpBtn(false);
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userID = userCredential.user.uid;
  
      // Generate a unique referral code
      const referralCode = await generateReferralCode();
      toast.success('Generating referral code...');
  
      // Save user data to Firestore
      const newUserRef = doc(db, 'users', userID);
      await setDoc(newUserRef, {
        email: email,
        phone: phoneNumber,
        signupdate: serverTimestamp(),
        userId: userID,
        usertype: 'referral',
        country: country,
        registeredVia: 'Web',
        fullname: fullName,
        referralCode: referralCode // Save the referral code
      });
      toast.success('Setting up an Account...');
  
      // Format the SMS message
      const message = `Hello ${fullName}, Welcome to the Ubunifu family! Your unique referral code is ${referralCode}. As a valued referral partner, you play a key role in connecting students with our innovative programs. Refer students using your code or admission link, and if they're admitted and make a payment, you will receive a reward of upto KES. 5,000 per student depending on the course. Let's build the future together!`;
      const transRef = "TransactionReference"; 
  
      await sendSms(message, phoneNumber, transRef);

  
      setShowProgress(false);
      setShowSignUpBtn(true);
  
      // Display success message and redirect
      swal({
        title: "Registration Successful!",
        text: "Your registration is complete. Welcome to Ubunifu!.",
        icon: "success",
        button: "Okay",
      }).then(() => {
        navigate('/dashboard');
      });
  
    } catch (error) {
      setShowProgress(false);
      setShowSignUpBtn(true);
      const errorMessage = error.message;
      swal('Sign Up Error!', errorMessage, 'error');
      toast.error(errorMessage, 'Sign Up Error');
    }
  }
  
  async function sendSms(message, phoneNumber, transRef) {
    const url = 'https://us-central1-ubunifu-college.cloudfunctions.net/api/send-sms'; 
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  return (
    <div className='d-flex loginContainer'>
      <div className='loginRight'>
        <Link to="/"><img src={logo} alt="" className='loginLogo' /></Link>
        <h5>Sign Up for an Account</h5>
        <br/> 

        <Form.Label className="inputLabel">Full Name</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faUser} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Full Name"
            aria-label="Full Name"
            type="text"
            className='loginForm'
            ref={fullNameRef}
          />
        </InputGroup>

        <Form.Label className="inputLabel">Phone Number</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faPhone} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Phone Number"
            aria-label="Phone Number"
            type="number"
            className='loginForm'
            ref={phoneNumberRef}
          />
        </InputGroup>

        <Form.Label className="inputLabel">Country</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faGlobe} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Select className="loginForm" ref={countryRef}>
              <CountryOptions/> 
          </Form.Select>
        </InputGroup>

        <Form.Label className="inputLabel">Email</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faEnvelope} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Enter your email"
            aria-label="Enter your email"
            type="email"
            className='loginForm'
            ref={emailRef}
          />
        </InputGroup>

        <Form.Label className="inputLabel">Password</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faLock} className="theLoginIcon" />
          </InputGroup.Text>
          <Form.Control
            placeholder="Enter your Password"
            aria-label="Enter your Password"
            type="password"
            className='loginForm'
            ref={passwordRef}
          />
        </InputGroup>

        {showAlert && <Alert variant={'warning'}>{errorMessage}</Alert>}

        {showSignUpBtn && (
          <button type="submit" onClick={SignUpUser} className='signInbtnn'>
            Sign Up
          </button>
        )}

       

        {showProgress && (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span> Signing up...</span>
          </Button>
        )}

        <Link to="/login" className='signupLink '>Already have an account? Sign in</Link>  
      </div>

      <div className='loginLeft'>
  <video src="https://res.cloudinary.com/ubunifu/video/upload/v1723917248/6987044-uhd_2160_3840_25fps_c96mfk.mp4" muted autoplay loop playsinline></video>
</div>
 
    </div>
  ); 
}

export default Register;
