import './App.css';
import Login from './Pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ResponsiveDash.css'
import './Respdesign.css' 
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Register from './Pages/Register';
import Home from './Pages/Home';
import Applications from './Pages/Applications';
import Marketing from './Pages/Marketing';
import Earnings from './Pages/Earnings';
import Payments from './Pages/Payments';
import Profile from './Pages/Profile';
import EditProfile from './Pages/EditProfile';
 
function App() {
  return (
    <div> 
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/login" element={<Login />} />

          <Route path="/dashboard" element={<Home />} /> 
          <Route path="/applications" element={<Applications />} />  
          <Route path="/marketing-materials" element={<Marketing />} />  
          <Route path="/earnings" element={<Earnings />} />  
          <Route path="/payments" element={<Payments />} />  

          <Route path="/profile" element={<Profile />} />  
          <Route path="/edit-profile" element={<EditProfile />} />  
        </Routes>       
      </BrowserRouter> 
    </div>
  );
}

export default App;
