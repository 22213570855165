import React , { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faSearch} from '@fortawesome/free-solid-svg-icons' 
import { faBars, faFileExport, faReceipt, faDollar,} from '@fortawesome/free-solid-svg-icons'
import logo from '../Images/logo-main-black.png'
import profilePictureContImage from '../Images/defprofile.png'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

//mobile navbar
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

//icons
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faCoffee} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { faUsd} from '@fortawesome/free-solid-svg-icons'
import { faMagic} from '@fortawesome/free-solid-svg-icons'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faBicycle } from '@fortawesome/free-solid-svg-icons';

function Dashnav() {

  const [userProfilePicture, setProfilePicture] = useState(profilePictureContImage);

  //instantiate auth
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
    //if user is logged in 
    const uid = user.uid;

      const q = query(collection(db, "users"), where("userId", "==", uid));
      getDocs(q).then((QuerySnapshot)=>{
          QuerySnapshot.forEach((doc)=>{

          const profilePic = doc.data().profilePic;

          let myxx = profilePic + "1";          
          if(myxx === "undefined1"){
            setProfilePicture(profilePictureContImage);         
          }else{
            setProfilePicture(profilePic);
          }

          })
      })

    } else {
      // User is signed out
      }
  });

  let navigate = useNavigate();
  function goToProfile(){
    navigate("/profile")
  }


  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const searchResultsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResultsRef]);

  const handleKeyUp = async (event) => {
    const value = event.target.value;
    console.log(value);

    if (value.trim() !== "") {
      const endValue = value + '\uf8ff';
      const q = query(collection(db, "users"), where("bio", ">=", value), where("bio", "<=", endValue));
      const querySnapshot = await getDocs(q);
      const searchResults = [];
      querySnapshot.forEach((doc) => {
        const profilePic = doc.data().profilePic || profilePictureContImage;
        const name = doc.data().bio;
        const userStatus = doc.data().userStatus;
        const userId = doc.data().userId;
        console.log(name);
        searchResults.push({ profilePic, bio: name, userStatus,userId });
      });
      setResults(searchResults);
    }else{
      setResults([]);
    }
  };

  function viewStudent(userId) {
    navigate('/view-student', { state: { myData: userId } });
  }

  



  return (
    <div className='dashNav'> 
        <div className='dashMenuIcon'>
          {/* <FontAwesomeIcon icon={faBars} className="dashNavIco "/> */}

          {[false, ].map((expand) => (
            <Navbar key={expand} bg="" className="" expand={expand} >
              <Container fluid>
              
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img src={logo} alt="" className='dashnavLogo' />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">

                      
                    <div className='leftLinkCont d-flex align-items-center'>
  <Link to="/dashboard" className='leftNavLinks'>
    <FontAwesomeIcon icon={faHome} className="laftNavIcon activelaftNavIcon" />
    Dashboard
  </Link>
</div>

<p className='mobileMenuTitles'>STUDENTS</p>
<div className='leftLinkCont d-flex align-items-center'>
  <Link to="/applications" className='leftNavLinks'>
    <FontAwesomeIcon icon={faUsers} className="laftNavIcon" />
    My Applicants
  </Link>
</div>

<div className='leftLinkCont d-flex align-items-center'>
  <Link to="/marketing-materials" className='leftNavLinks'>
    <FontAwesomeIcon icon={faFileExport} className="laftNavIcon" />
    Marketing materials
  </Link>
</div>

<div className='leftLinkCont d-flex align-items-center'>
  <Link to="/earnings" className='leftNavLinks'>
    <FontAwesomeIcon icon={faReceipt} className="laftNavIcon" />
    My earnings
  </Link>
</div>

<div className='leftLinkCont d-flex align-items-center'>
  <Link to="/payments" className='leftNavLinks'>
    <FontAwesomeIcon icon={faDollar} className="laftNavIcon" />
    My Payments 
  </Link>
</div>

<p className='mobileMenuTitles'>ACCOUNT</p>
<div className='leftLinkCont d-flex align-items-center'>
  <Link to="/profile" className='leftNavLinks'>
    <FontAwesomeIcon icon={faUser} className="laftNavIcon" />
    Profile
  </Link>
</div>



                      
                    </Nav>

                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>

        <div className='searchDashCont'>
          <input placeholder='Search for a student' onKeyUp={handleKeyUp}/>
          <FontAwesomeIcon icon={faSearch} className="dashSearchIcon "/>
        </div>

        {results.length > 0 && (
        <div className='searchResults' ref={searchResultsRef}>
          {results.map((result, index) => (
            <div key={index} className='resultItem' onClick={() => viewStudent(result.userId)}>
              <div className='resultItemDiv'>
                <img src={result.profilePic || profilePictureContImage} alt={`${result.bio}'s profile`} /> 
              </div>
              <div>
                <p>{result.bio}</p>
                <p className='statusResult'>{result.userStatus}</p>
              </div>
            </div>
          ))}
        </div>
         )}
        

        <div className='allNotifCont'>
          <div className='chatCont'>
            <FontAwesomeIcon icon={faEnvelope} className="dashNoticIcon "/>
            <p className='notifCounter'>0</p>
          </div>
          <div className='NotifCont'>
            <FontAwesomeIcon icon={faBell} className="dashNoticIcon "/>
            <p className='notifCounter2'>0</p>
          </div>
          <div className='profCont' onClick={goToProfile}>
            <img src={userProfilePicture} alt="" />
          </div>

        </div>        
  </div>
  )
}

export default Dashnav