// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration for the second project
const firebaseConfig2 = {
  apiKey: "AIzaSyAM2P7cVGotXsUkx76iN6R9ySAWzSKn1ME",
  authDomain: "ubunifu-college.firebaseapp.com",
  databaseURL: "https://ubunifu-college.firebaseio.com",
  projectId: "ubunifu-college",
  storageBucket: "ubunifu-college.appspot.com",
  messagingSenderId: "772246694093",
  appId: "1:772246694093:web:4e12c420cad7fe29e72184",
  measurementId: "G-2PVBC1D0QP"
};

// Initialize Firebase with a custom app name
const app2 = initializeApp(firebaseConfig2, "secondary");
const analytics2 = getAnalytics(app2);
const db2 = getFirestore(app2);

export { app2, analytics2, db2 };
 